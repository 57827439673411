import React, { useState, useCallback } from "react";

export default function useFormHook(defaultValues = {}) {
  const [formValues, setFormValues] = useState(defaultValues as { [key: string]: any });
  const register = useCallback((name, defaultValue) => {
    setFormValues((prevState) => ({ ...prevState, [name]: defaultValue }));
  }, []);

  const unregister = useCallback((name) => {
    setFormValues((prevState) => {
      const copy = JSON.parse(JSON.stringify(prevState));
      delete copy[name];
      return copy;
    });
  }, []);

  const setValue = useCallback(
    (name, newValue) => setFormValues((prevState) => ({ ...prevState, [name]: newValue })),
    []
  );
  return { formValues, setFormValues, register, unregister, setValue };
}
